<template>
  <v-main class="auth-pages">
    <div
      class="header-auth position-relative ma-4 pb-16 pt-16 border-radius-xl"
      :class="'bg-gradient-default'"
    >
<!--      <v-img-->
<!--        src="@/assets/img/shapes/waves-white.svg"-->
<!--        alt="pattern-lines"-->
<!--        class="position-absolute opacity-6 start-0 top-0 w-100"-->
<!--        v-if="this.$route.name == 'Pricing'"-->
<!--      ></v-img>-->
      <v-container>
        <v-row class="d-flex mt-5">
          <v-col cols="12" md="6" class="mx-auto py-0">
            <h3 class="text-h3 font-weight-bold text-white text-center mb-2">
              {{'Welcome'|trans}}
            </h3>

            <p
              class="
                text-white
                font-size-root
                text-center
                font-weight-thin
                mb-12
              "
            >
              {{ this.$translator.trans('The Path Forward is a volunteer led project dedicated to supporting war refugees in rebuilding their lives. Our digital platform will connect displaced refugees to our Yale students and their networks to provide mutual aid as a community. Our immediate mission is to provide resources and support to those directly affected by the Ukraine crisis.', {}, 'website', this.$store.getters.getLocale) }}
            </p>
            <span class="d-flex justify-center align-center">
                <v-btn text href="https://www.instagram.com/the_pathforward/" target="_blank"><v-icon color="white" large>fab fa-instagram</v-icon></v-btn>
                <v-btn text href="https://www.facebook.com/profile.php?id=100080137792551" target="_blank"><v-icon color="white" large>fab fa-facebook</v-icon></v-btn>
                <v-btn text href="https://twitter.com/_thepathforward" target="_blank"><v-icon color="white" large>fab fa-twitter</v-icon></v-btn>
              </span>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="position-relative border-radius-xl ">
      <v-container class="py-0">
        <v-row>
          <v-col lg="6" md="6" class="d-flex flex-column mx-lg-0 mx-auto">
            <v-card class="card-shadow border-radius-xl">
              <div class="px-6 pt-6 pb-4 text-center">
                <v-btn
                  elevation="0"
                  small
                  :ripple="false"
                  height="21"
                  class="
                    border-radius-xl
                    font-weight-bolder
                    px-3
                    py-3
                    badge-font-size
                    ms-auto
                    text-dark
                  "
                  color="#e9ecef"
                  >{{ refugeePanel.subtitle }}
                </v-btn>
                <h1 class="text-h1 text-typo font-weight-bold mt-1">
                  {{ refugeePanel.title }}
                </h1>

                <v-list
                  class="py-6 text-lg-start text-center"
                  color="transparent"
                >
                  <v-list-item
                    v-for="item in refugeePanel.items"
                    :key="item.title"
                    class="px-0 py-0"
                  >
                    <v-list-item-avatar
                      :size="24"
                      color="#fff"
                      class="my-0 me-4 shadow"
                      :class="`bg-gradient-default`"
                    >
                      <v-icon size="9" class="text-white">{{
                        item.icon
                      }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        v-text="item.title"
                        class="text-body-1 ls-0 text-muted"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    btn-primary
                    py-3
                    text-xs
                    px-6
                    ls-0
                    w-100
                    border-radius-md
                  "
                  :class="`bg-gradient-default`"
                  :to="{ name: 'submit-request-page' }"
                >
                  {{ "Submit request"|trans }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col lg="6" md="6" class="d-flex flex-column mx-lg-0 mx-auto">
            <v-card class="card-shadow border-radius-xl">
              <div class="px-6 pt-6 pb-4 text-center">
                <v-btn
                  elevation="0"
                  small
                  :ripple="false"
                  height="21"
                  class="
                    border-radius-xl
                    font-weight-bolder
                    px-3
                    py-3
                    badge-font-size
                    ms-auto
                    text-dark
                  "
                  color="#e9ecef"
                  >{{ volunteerPanel.subtitle }}
                </v-btn>
                <h1 class="text-h1 text-typo font-weight-bold mt-1">
                  {{ volunteerPanel.title }}
                </h1>

                <v-list
                  class="py-6 text-lg-start text-center"
                  color="transparent"
                >
                  <v-list-item
                    v-for="item in volunteerPanel.items"
                    :key="item.title"
                    class="px-0 py-0"
                  >
                    <v-list-item-avatar
                      :size="24"
                      color="#fff"
                      class="my-0 me-4 shadow"
                      :class="`bg-gradient-default`"
                    >
                      <v-icon size="9" class="text-white">{{
                        item.icon
                      }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        v-text="item.title"
                        class="text-body-1 ls-0 text-muted"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    btn-primary
                    py-3
                    text-xs
                    px-6
                    ls-0
                    w-100
                    border-radius-md
                  "
                  :class="`bg-gradient-default`"
                  :to="{ name: 'volunteer-register' }"
                >
                  {{ 'Register'|trans }}
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-main>
</template>
<script>
export default {
  name: "landing",
  data() {
    return {
    };
  },
  computed:{
    refugeePanel(){
      return {
        title: this.$translator.trans("I need help"),
        subtitle: this.$translator.trans("I'm a refugee"),
        items: [
          {
            title: this.$translator.trans("Searching for accommodation"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("Searching for guidance"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("Searching for travel assistance"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("Other support"),
            icon: "fa fa-minus",
          },
        ],
      };
    },
    volunteerPanel(){
      return {
        title: this.$translator.trans("I want to help"),
        subtitle: this.$translator.trans("I'm a volunteer"),
        items: [
          {
            title: this.$translator.trans("I can help with accommodation"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("I can help with guidance"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("I can help with travel arrangements"),
            icon: "fa fa-minus",
          },
          {
            title: this.$translator.trans("Others"),
            icon: "fa fa-minus",
          },
        ],
      };
    }
  }
};
</script>
